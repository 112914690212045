import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/handbook/post-layout/post-layout-secondary.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1200px"
      }
    }}>{`
      `}<a parentName="span" {...{
        "className": "gatsby-resp-image-link",
        "href": "/static/aebb9136b4bda211821729bb8c1cf3ba/85e74/5496d33cc9d822085694b9fa9c0e0b282a8b194b.png",
        "style": {
          "display": "block"
        },
        "target": "_blank",
        "rel": "noopener"
      }}>{`
    `}<span parentName="a" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "54.666666666666664%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACqklEQVQozz2MWW/iBgAG/fO3lfrQRmq7bRX2SCCkGLbhvsJlwJgrMWB8YHAgHAESjkBaNQtTKVp1Xj7Nw3wC3zgej2/7snlk8zBit5ywe5rxvJjwslnysl6yXy/5e/vIfjXneTnhn93mW8z/CNvdke3+8Cavr7B/WrKdjVjPlzwvpm+n64cJ28U9u+WMzXzOdj5lMx2ye1pxOMDh+JV/vx54PRwQZosdunNPXA0gFs5JtkKoto1y2+VG7xMPdhDPFSKBFqp+h6JqtK07JCPHF/mCYNVL1ahTaXZodE2E1eMT9tAm1PXhrXwkqIkolkZRVVE0ky++Nu4PCn5vm5oxoNi6pWHZxNpR3EUXl8pnClYDSe1S7fUROtYAudklEJLxiDnEYJmgbOIvdQnKFuJfVdzeAueeLGJWRSx2uFJsko0W8YpCqlojcWNxpfSINh2EW3NMpmQQeJ/B/VMU/+850pUpV5JDUh4TOM3hPongOQkRy9uEig6J6pzGbZ1s2k+5GKbW0UkoPQrqHcJ4vkHTR1x7rgmehkl7syiVPpmChizbbx5yRYifJVDkHjmpS1lxsNpF5OvPqCUfYTXCieTnj3oI4WW3ZTafUXd6pBpV6j2T/G8i8Xen5N/7qBk6qZsatZ5J2XVF/HsXmR/OsGsGrU4PQxuQGw9wtUtcOi2E8fQB3b6jYjokayplzebalyH2MULGl0HWbJL1FlLLePPYpwjpswSNtknRNFAGA6xmCjV8ipE+RxhNHjB7FsNulLp0gdOJYpWKKJEkhiThtKM0il6Muh+jkKEaS6FnSgTsD/yov+Pn/ncU7hto1gp9uEZYrTbMxgP2AxFd+oV9P8AiH+PG42GWjbG3/XSlX9laIhs5jXp5wTAWZq1IGIkwo3ySarSJ71OF6J8q/wF9OfDcLCP/BwAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="a" {...{
          "className": "gatsby-resp-image-image",
          "alt": "5496d33cc9d822085694b9fa9c0e0b282a8b194b",
          "title": "5496d33cc9d822085694b9fa9c0e0b282a8b194b",
          "src": "/static/aebb9136b4bda211821729bb8c1cf3ba/c1b63/5496d33cc9d822085694b9fa9c0e0b282a8b194b.png",
          "srcSet": ["/static/aebb9136b4bda211821729bb8c1cf3ba/5a46d/5496d33cc9d822085694b9fa9c0e0b282a8b194b.png 300w", "/static/aebb9136b4bda211821729bb8c1cf3ba/0a47e/5496d33cc9d822085694b9fa9c0e0b282a8b194b.png 600w", "/static/aebb9136b4bda211821729bb8c1cf3ba/c1b63/5496d33cc9d822085694b9fa9c0e0b282a8b194b.png 1200w", "/static/aebb9136b4bda211821729bb8c1cf3ba/85e74/5496d33cc9d822085694b9fa9c0e0b282a8b194b.png 1436w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
  `}</a>{`
    `}</span>
    <img src="f9e074023a369b5bc744ab01167c58a7e0a21daa.png" style={{
      "width": "7.32in",
      "height": "3.97222in"
    }} />
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1200px"
      }
    }}>{`
      `}<a parentName="span" {...{
        "className": "gatsby-resp-image-link",
        "href": "/static/d9a4b3e161b15b187a821df5af231ba1/bd9eb/0a16cb8af57edea716a05b20967702e4cc7b26e4.png",
        "style": {
          "display": "block"
        },
        "target": "_blank",
        "rel": "noopener"
      }}>{`
    `}<span parentName="a" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "54.333333333333336%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACtUlEQVQoz02QzXPiBABH8/d68LRHR73tjB8zujM627E6jku1pYBLhdIWCIGQlAIJEMpnAoEA4SOEtNtSqV19zva07/Lmd3iXn8BH/Pfve+43S94tJ9x5U3Z3N8/779s1D7c+9/6Cx+0t28BjG6zYBsvn5mOEzc0T9/ePPP3zxMN2RzB3uFlNWS/m3AUegevguy7+1GY9G+Gv1mwWUzbuGG/isHvY8fih3b1/tuB5N9jzOZJZI6EXUbsG8usTTr/6A/XgHNk2SOglrkYV/MUeZvNbvMkraq5Gsl5G7OrMpr9iGS+Zj35AWK88+q5DtKeyryQ47VUwRB0tVaKhNoh0ZPaLKc6tHHPzSxqFT5k0XyCORPaVFOFWEav/DUb+E0bNzxHUWgepZnGkDglJHY6ubCK9NX9aAZH2isOixW+5Dm/SDUJHEm/CMocxlbDcJ5TvcahYhJNVQmGZ4/glQrDZMJkuyBY0YvEMimFxsfY5/XCDH5CTNSInaWRJI/ddjOMvfiLzdRg1XyeWEMkWdeRfzgh/tof4fQyhZTqUmhYHaZ3XUYmY2kW2A4p2gDTYEErX+TFa4DhnUGnOqF7P0dtLTpQOe1GJg7SGasyotBZU2wuE2crHsWx6yTMuf4/g5CW6WoqqHKNfE9FO81wcxDFEmWZTRFH/Qm+kGRYLFA6OuU6e09PSVJS3NPVzBNed03Om5AZjEo025emEduOCsvIWu6uwGY5Y9gYs7SGZfoVIOUuqV2Y2mzI1R3iTOVanSPUyTruZRRjYDnXTIVK1CcktUtdzilcDzjJ1SjUH0RiQKrcRaybyZZ/zTJ3Lik3WmBEttjmrTygoFvGoQkFsIfieh+OukHSTZKFOzVwy7Lm0tD7DsU92tuLUHFPy3jEeejS1PiNzSbXrEpd0ZGNEarzi54pBfOjyPx+P9POO/p2DAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="a" {...{
          "className": "gatsby-resp-image-image",
          "alt": "0a16cb8af57edea716a05b20967702e4cc7b26e4",
          "title": "0a16cb8af57edea716a05b20967702e4cc7b26e4",
          "src": "/static/d9a4b3e161b15b187a821df5af231ba1/c1b63/0a16cb8af57edea716a05b20967702e4cc7b26e4.png",
          "srcSet": ["/static/d9a4b3e161b15b187a821df5af231ba1/5a46d/0a16cb8af57edea716a05b20967702e4cc7b26e4.png 300w", "/static/d9a4b3e161b15b187a821df5af231ba1/0a47e/0a16cb8af57edea716a05b20967702e4cc7b26e4.png 600w", "/static/d9a4b3e161b15b187a821df5af231ba1/c1b63/0a16cb8af57edea716a05b20967702e4cc7b26e4.png 1200w", "/static/d9a4b3e161b15b187a821df5af231ba1/bd9eb/0a16cb8af57edea716a05b20967702e4cc7b26e4.png 1442w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
  `}</a>{`
    `}</span>
    <h2 {...{
      "id": "a-student-guide-to-success-in-the-psi-programme",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#a-student-guide-to-success-in-the-psi-programme",
        "aria-label": "a student guide to success in the psi programme permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`A Student Guide To Success In The Psi Programme`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Set good habits from the start.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Use a planner to record homework and dates assignments are due.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Maintain a high level of quality in your assignments.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Keep up with your work and hand in all assignments.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Ask your teachers for assistance if you do not understand the work being covered.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Ask the teacher to explain why you did not do as well as you expected on an assignment/ test.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Set yourself clearly defined targets.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Establish credibility with your teachers by being a reliable, mature and honest student.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Speak to the teacher before the work is due if you are having a problem with an out-of-class assignment.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Advise your teacher at least a week in advance if you will miss class for a short or extended time. This allows the teacher time to prepare plans for you. Do not expect the teacher to assign work with only a day or two of warning.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Maintain a good attendance record. Missing classes will lead to fragmentation of course content and mediocre or, more likely, poor academic performance.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Remember it is not the teacher’s job to re‐teach a lesson because you missed it; it is your job to locate class notes, etc.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Maintain your personal discipline and establish a reputation for maturity, responsibility and reliability. These are skills that will bring you success at every grade level, including university, and will prepare you for your life beyond school.`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "glossary-of-terms",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#glossary-of-terms",
        "aria-label": "glossary of terms permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Glossary of Terms`}</h2>
    <h3 {...{
      "id": "whole-school-glossary",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#whole-school-glossary",
        "aria-label": "whole school glossary permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`WHOLE SCHOOL GLOSSARY`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th"><em parentName="strong">{`Term`}</em></strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th"><em parentName="strong">{`Definition`}</em></strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`AGM`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The Annual General Meeting, which is open to all members of the School community who wish to hear a summary of the School’s progress against its strategic goals, as well as to hear announcements for the next year, including the election of new Board members`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`CEESA`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Central and Eastern European Schools Association - a coalition of schools in this region that provide athletic and academic activities for Secondary students as well as professional development and support for teachers`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`CIS`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Council for International Schools, one of the School’s accrediting organisations`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Differentiation`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Planning for different learning needs and styles`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`EAL`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`English as an Additional Language`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`ECA`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Extra-curricular activities - A programme for students that provides them with opportunities to explore the arts, sports, IT, games, academic enrichment and leadership beyond the classroom`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`ELT`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Educational Leadership Team (director, principals, deputy principals, IB coordinators)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`GDPR`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The European General Data Protection Regulations (GDPR) provide strict guidelines that help ensure that student, staff and parent information - in print or digitally - is kept secure and private.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`HOD`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Head of Department`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`IB`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`International Baccalaureate, one of the School’s accrediting organisations and the base of the School’s curricular programmes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`IT`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Information Technology`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`LP`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Learner Profile - a key component of the IB that focuses on student attitudes towards self and others. A list of the terms and their definitions is included in all major handbooks`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`LST`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Learning Support Team, usually the appropriate building principal and counsellor, along with the learning resource teacher. This team meets weekly to discuss students at risk in order to create strategies to support their social, emotional and academic growth`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`MAP`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Measure of Academic Progress, an on-line test for students in reading and mathematics given twice a year to students in Grades 3-8.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Mother tongue`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The language(s) learned first; the language(s) identified with as a ‘native’ speaker; the language known best; the language used most`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`MT`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Management Team - Educational Leadership Team (see above) plus Business Manager and IT director`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`MyIB`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`My IB is the International Baccalaureate's new central location for IB resources and applications. You can access the services you use with one username and password.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`MyPSI`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`MyPSI is where parents go to look at their student’s grades (secondary), report cards and standardized testing results`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`NEASC`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`New England Association of Schools and Colleges, one of the School’s accrediting organisations`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`OCC`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The On-Line Curriculum Centre is an IB resource for teachers and administrators`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`OPI`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The On-line Placement Inventory is the assessment tool in maths and English used for Secondary student admissions`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`PA`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Professional Assistants, who support the two Principals and the Director`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`PE / PHE`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Physical Education or Physical and Health Education - a part of the EC - Grade 10 curriculum focusing on sports, physical activity and a healthy lifestyle`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`PRC`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The International Baccalaureate's Programme Resource Centre- an online password protected part of MyIB where teacher resources are accessible.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`PSI`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Pechersk School International`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`PTC`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Parent teacher conferences, held in both Primary and Secondary`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`SLC / SLS`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Student-led conferences or student-led showcases, held in both Primary and Secondary. Students present to parents their online and paper portfolios showcasing what and how they have learnt`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`TA`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Teaching Assistants, who provide support in the classroom for Primary teachers and some Secondary departments`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`TS`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Teaching Support staff, who provide clerical support for teaching and administrative staff`}</td>
        </tr>
      </tbody>
    </table>
    <p><strong parentName="p">{`SECONDARY SCHOOL / MIDDLE YEARS PROGRAMME`}</strong></p>
    <table>
    <colgroup>
        <col style={{
          "width": "16%"
        }} />
        <col style={{
          "width": "83%"
        }} />
    </colgroup>
    <thead>
    <tr className="header">
        <th>Term</th>
        <th>Definition</th>
    </tr>
    </thead>
    <tr className="odd">
        <td>Achievement Level</td>
        <td>The mark given to indicate that the student’s work matches the corresponding descriptor.</td>
    </tr>
    <tr className="header">
        <td>Alignment</td>
        <td>Agreement in principle and practice among the written curriculum, the taught curriculum and the assessed curriculum</td>
    </tr>
    <tr className="odd">
        <td>ATL</td>
        <td>Approaches to Learning are concerned with the development of thinking skills, strategies and attitudes and the ability to reflect on one’s own learning</td>
    </tr>
    <tr className="header">
        <td>Articulation</td>
        <td>The logical progression of learning objectives from year to year (vertical) and during an academic year (horizontal)</td>
    </tr>
    <tr className="odd">
        <td>Assessment criteria</td>
        <td>The standards on which student work is evaluated in all subject areas and in the Personal Project</td>
    </tr>
    <tr className="header">
        <td>Assessment rubric</td>
        <td>A grid that contains levels and descriptors</td>
    </tr>
    <tr className="odd">
        <td>Assessment strategy</td>
        <td>The method or approach that teachers use when gathering information about student learning, for example, observation, open-ended tasks, selected responses</td>
    </tr>
    <tr className="header">
        <td>Assessment task</td>
        <td>The activities that students do and that teachers evaluate</td>
    </tr>
    <tr className="odd">
        <td>Assessment tool</td>
        <td>
            <p>The term assessment refers to the wide variety of methods or tools that educators use to evaluate, measure, and document the academic readiness, learning progress, skill acquisition, or educational needs of students.</p>
            <p>It may also refer to the device teachers use to measure and record assessment information and data collected, for example, anecdotal records</p>
        </td>
    </tr>
    <tr className="header">
        <td>Criterion-related assessment</td>
        <td>An assessment process based on determining achievement levels against previously agreed criteria. MYP assessment is criterion-related</td>
    </tr>
    <tr className="odd">
        <td>Curriculum framework</td>
        <td>An organised plan for teaching and learning.In the MYP, the curriculum framework includes conceptual understanding, global contexts, approaches to teaching and approaches to learning, and significant content across a broad and balanced range of disciplines.</td>
    </tr>
    <tr className="header">
        <td>Descriptors</td>
        <td>Achievement level descriptors describe the differences in student accomplishment for each assessment criterion and correspond to the achievement level that should be awarded</td>
    </tr>
    <tr className="odd">
        <td>E-Assessment</td>
        <td>On-line testing and assessment that will lead to MYP Certification. This process should begin at the end of the 2015-16 school year for Grade 10 students only.</td>
    </tr>
    <tr className="header">
        <td>Formative assessment</td>
        <td>Ongoing assessment aimed at providing information to guide teaching and improve student performance. This work is not graded but is reviewed and used to prepare students for the summative assessments.</td>
    </tr>
    <tr className="odd">
        <td>Global engagement</td>
        <td>Inquiring, acting and reflecting on global challenges in local, national and international dimensions</td>
    </tr>
    <tr className="header">
        <td>Grade boundary</td>
        <td>The lowest and highest criterion levels totals corresponding to a particular holistic grade.</td>
    </tr>
    <tr className="odd">
        <td>Horizontal planning/horizontal articulation</td>
        <td>Planning horizontally involves a team of teachers of the same grade level working together to plan for learning.</td>
    </tr>
    <tr className="header">
        <td>IB MYP certificate</td>
        <td>IB-issued certificate that signifies the successful completion of requirements for the programme; accompanied by IB MYP course results</td>
    </tr>
    <tr className="odd">
        <td>IB MYP course results</td>
        <td>Official IB document that provides a record of successful examination and/or ePortfolio grades for MYP e-Assessment candidates who do not attain the IB MYP certificate.</td>
    </tr>
    <tr className="header">
        <td>Interdisciplinary</td>
        <td>Combining or involving two or more branches of learning or fields of academic study. In the MYP, interdisciplinary study can be developed both within and between/among subject groups.</td>
    </tr>
    <tr className="odd">
        <td>Internal assessment</td>
        <td>The assessment of a student’s work that is carried out by a student’s teacher(s)</td>
    </tr>
    <tr className="header">
        <td>Internal standardisation</td>
        <td>The process by which all teachers in a department develop and ensure a common understanding and application of criteria and descriptors</td>
    </tr>
    <tr className="odd">
        <td>MYP</td>
        <td>The IB Middle Years Programme designed for students between the ages of 11 and 16 years</td>
    </tr>
    <tr className="header">
        <td>Objective</td>
        <td>The skills, knowledge and understandings that are addressed in each course/project and based on the assessment criteria.</td>
    </tr>
    <tr className="odd">
        <td>Personal Project (PP)</td>
        <td>The Personal Project is the culmination of the students’ experience in the MYP and shows their experience of ATL and global contexts. It is completed in the final year of the programme.</td>
    </tr>
    <tr className="header">
        <td>Personal Project Supervisor</td>
        <td>The member of staff within a school who is responsible for working directly with the student on the completion of the personal project.</td>
    </tr>
    <tr className="odd">
        <td>SAS</td>
        <td>Student Academic Support - the combination of class time and pullout time that we use to support students with identified needs</td>
    </tr>
    <tr className="header">
        <td>Service &amp; Action</td>
        <td>Through responsible action, tightly connected with sustained inquiry and critical reflection, young people and adults can develop the kinds of attributes described by the learner profile that are essential for success in future academic pursuits and for adult life.</td>
    </tr>
    <tr className="odd">
        <td>SWW</td>
        <td>The School-wide write is a PSI assessment tool to evaluate student writing in Grades 6 - 10. It is given twice a year; students and teachers then set goals for writing improvement.</td>
    </tr>
    <tr className="header">
        <td>Unit</td>
        <td>A series of lessons, focused through a statement of inquiry and inquiry questions, designed to help students achieve some of the objectives of an MYP subject group.</td>
    </tr>
    <tr className="odd">
        <td>Vertical planning/vertical articulation</td>
        <td>The goal of vertical planning is to sequence learning (in terms of subject‑group objectives and ATL) to ensure continuity and progression from year 1 to year 5.</td>
    </tr>
    </table>
    <p>{`For more information on the MYP in general, please visit the `}<a parentName="p" {...{
        "href": "http://ibo.org/myp/"
      }}><u>{`IB MYP website`}</u></a>{`. For more information about the MYP at PSI, please visit the `}<a parentName="p" {...{
        "href": "https://www.psi.kiev.ua/page.cfm?p=535&LockSSL=true"
      }}><u>{`PSI MYP website page`}</u></a>{`.`}</p>
    <p><strong parentName="p">{`Appe`}</strong></p>
    <p><strong parentName="p">{`PSI SECONDARY SCHOOL / IB AND PSI DIPLOMA PROGRAMMES`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Term`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Definition`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`CAS`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Creativity, Activity, and Service - a required part of the DP`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Certificates`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Students not taking or earning the full IB Diploma can earn separate certificates for every successfully completed DP course`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Core`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The Core of the IBDP includes the Extended Essay, the Theory of Knowledge Course, and CAS (Creativity, Activity, and Service).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`DP`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`IB Diploma Programme for students ages 16-19 (Grades 11 and 12)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`EE`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The Extended Essay, a required part of the DP - a 4000 word, in-depth study in one subject or an interdisciplinary topic.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Group`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Subject courses are placed in groups depending on the field: (1) Studies in Language and Literature, (2) Language Acquisition, (3) Individuals and Societies,(4) Science,(5) Mathematics, and the (6) Arts.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`HL`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Higher Level course (240 hours of instruction time). Students must take a minimum of three courses at HL to qualify for the full IB Diploma.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`IB Diploma`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Students who successfully meet all requirements of the Diploma Programme earn a diploma from the IB.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Internal Assessment (IA)`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A piece of subject work that is internally assessed and externally moderated`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`PSI Courses`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Students not taking courses at DP level can earn credit toward graduation from PSI by taking courses designed, taught and assessed by PSI.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`PSI Diploma`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All students who successfully meet the graduation requirements of PSI earn a PSI Diploma. Students may also earn IB certificates or the full IB Diploma in addition to this award. The PSI Diploma meets international accreditation standards.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`SL`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Standard level course (150 hours of instruction time. Students on the full IB Diploma normally take three SLs.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`SSST`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`School Supported Self-Taught Language A courses offered/supported by PSI in Group 1 of the Diploma Programme.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`TOK`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Theory of Knowledge - a required course for the full IB Diploma that is also embedded into all subject areas`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Transcripts`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A record of all semester grades earned from Grades 9-12. This document is usually requested by North American universities as part of the admissions process.`}</td>
        </tr>
      </tbody>
    </table>
    <blockquote>
      <p parentName="blockquote">{`For more information about the IB Diploma Programme in general, please visit the `}<a parentName="p" {...{
          "href": "http://ibo.org/diploma/"
        }}><u>{`IB Diploma Programme website`}</u></a>{`. For more information about the DP at PSI, please visit the `}<a parentName="p" {...{
          "href": "https://www.psi.kiev.ua/page.cfm?p=536&LockSSL=true"
        }}><u>{`PSI DP website page`}</u></a>{`.`}</p>
    </blockquote>
    <h2 {...{
      "id": "reference-list",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#reference-list",
        "aria-label": "reference list permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Reference List`}</h2>
    <p>{`IB Learner Profile 2013 (`}<a parentName="p" {...{
        "href": "http://occ.ibo.org/ibis/occ/Utils/getFile2.cfm?source=/ibis/occ/home/subjectHomeMYP.cfm&filename=dSpace%2Fen%2Fg_0_iboxx_amo_1702_1_e%2Epdf"
      }}><u>{`http://occ.ibo.org/ibis/occ/Utils/getFile2.cfm?source=/ibis/occ/home/subjectHomeMYP.cfm&filename=dSpace%2Fen%2Fg_0`}{`_`}{`iboxx_amo_1702_1`}{`_`}{`e%2Epdf`}</u></a>{`)`}</p>
    <p>{`MYP: From Principles into Practice (2014) (`}<a parentName="p" {...{
        "href": "https://ibpublishing.ibo.org/server2/rest/app/tsm.xql?doc=m_0_mypxx_guu_1409_2_e&part=1&chapter=1"
      }}><u>{`https://ibpublishing.ibo.org/server2/rest/app/tsm.xql?doc=m_0`}{`_`}{`mypxx_guu_1409_2`}{`_`}{`e&part=1&chapter=1`}</u></a>{`)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      